import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export default createGlobalStyle`

${reset};

  html {
    overflow: hidden;    
    font-family: Arial, Helvetica, sans-serif;    
  }
  * {
    box-sizing: border-box;  
  } 

  .babylonJS{
    background: transparent;
    display: none;
  }
`;
