/* eslint-disable react/no-unknown-property */
import { Suspense, useRef } from 'react';
import type { AbstractMesh, PBRMaterial, Scene as SceneType } from '@babylonjs/core';
import { GlowLayer } from '@babylonjs/core';
import { Texture } from '@babylonjs/core';
import { Vector3, Color3, Color4, CubeTexture } from '@babylonjs/core';
import type { SceneEventArgs } from 'react-babylonjs';
import { Model, Scene } from 'react-babylonjs';
import '@babylonjs/loaders/glTF';

type VHSProps = {
  setLoadingOpacity: (value: number) => void;
};

function VHS({ setLoadingOpacity }: VHSProps) {
  const sceneRef = useRef<SceneType>();
  const vhsRef = useRef<AbstractMesh>();

  const setupScene = ({ scene }: SceneEventArgs) => {
    scene.clearColor = new Color4(0, 0, 0, 0);
    const environmentTexture = new CubeTexture('./assets/environment.env', scene);
    scene.environmentTexture = environmentTexture;
    sceneRef.current = scene;

    scene.onReadyObservable.add(() => {
      setLoadingOpacity(0);
    });
  };

  const setupVHS = (vhs: AbstractMesh) => {
    vhsRef.current = vhs;
    const scene = sceneRef.current;
    if (!scene) return;

    vhs.rotation.z -= Math.PI / 6;

    const glassMaterial = scene.getMaterialByName('Glass') as PBRMaterial;
    if (!glassMaterial) return;
    glassMaterial.separateCullingPass = true;
    glassMaterial.transparencyMode = 3;
    glassMaterial.metallic = 0.7;
    glassMaterial.roughness = 0;
    glassMaterial.albedoColor = new Color3(0.027, 0, 1).toLinearSpace();
    glassMaterial.alpha = 0.55;

    const baseMaterial = scene.getMaterialByName('VHS-bakeado2') as PBRMaterial;
    const baseLightmap = new Texture('./assets/textures/baseLM.jpg', scene, false, false);
    if (!baseMaterial) return;
    baseMaterial.lightmapTexture = baseLightmap;
    baseMaterial.lightmapTexture.level = 0.7;
    baseMaterial.roughness = 0.2;
    baseMaterial.metallic = 1;

    const gl = new GlowLayer('glow', scene);
  };

  return (
    <Scene
      onSceneMount={(scene: SceneEventArgs) => {
        setupScene(scene);
      }}
    >
      <arcRotateCamera
        name="arcRotateCamera"
        target={new Vector3(-0.3, 0.5, 0.1)}
        alpha={Math.PI / 2}
        beta={Math.PI / 2 - 0.05}
        radius={9}
        lowerRadiusLimit={4}
        upperRadiusLimit={15}
        upperBetaLimit={Math.PI - 0.5}
        minZ={0.01}
        panningSensibility={0}
        useAutoRotationBehavior
        wheelPrecision={20}
        pinchPrecision={20}
      />
      <hemisphericLight
        name="hemisphericLight"
        direction={new Vector3(0, 1, 0)}
        specular={new Color3(0, 0, 0)}
        intensity={0}
      />

      <Suspense fallback={<plane name="plane" size={0.2}></plane>}>
        <Model
          name="calendar"
          sceneFilename="vhs2.glb"
          rootUrl="./assets/mesh/"
          onCreated={(vhs: AbstractMesh) => {
            setupVHS(vhs);
          }}
        ></Model>
      </Suspense>
    </Scene>
  );
}

export default VHS;
