import { BackgroundWrapper } from './Background.css';
import BackgroundVideo from './assets/background.gif';

export default function Background() {
  return (
    <BackgroundWrapper>
      <img src={BackgroundVideo}></img>
    </BackgroundWrapper>
  );
}
